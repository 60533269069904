import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Layout from "../components/layout";
import Stars from "../components/stars";
import VintageCars from "../assets/icons/vintage-car.svg";
import Light from "../assets/icons/light.svg";
import LogBook from "../assets/icons/log-book.svg";
import Brake from "../assets/icons/brake.svg";
import Unlock from "../assets/icons/unlock.svg";
import { SEO } from "../components/seo"

const Mechanical = () => {

    return (
        <Layout>
            <Container className="p-0 pdrm-sub__hero--wrapper">
                <Container className="pdrm-sub__hero p-0 py-5">
                    <Row className="pdrm-sub__hero__row g-0">
                        <Col lg={10} sm={12}>
                        <div className="pdrm-sub__hero__text-wrapper">
                            <Stars></Stars>
                            <h1>Mechanical</h1>
                            <p>Patriot also offer an array of mechanical services including log book &amp; brake servicing, diagnostic code reading and clearing (engine lights, air bag lights etc), classic car servicing &amp; maintenance, and vehicle unlocking.</p>
                            <Button href="tel:0407348941" className="pdrm-button secondary">Get a quote</Button>  
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="pdrm-sub__banner--services">
                <Container fluid className="pdrm-sub__pros">
                    <Row>
                        <Col xs={12}>
                            <h1>Our Mechanical Services</h1>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={LogBook} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Log book servicing</h3>
                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Brake} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Brake Servicing</h3>
                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Light} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Diagnostic code reading &amp; clearing</h3>
                            </div>
                        </Col>
                        <Col  sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={VintageCars} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Classic car serving &amp; maintenance</h3>
                            </div>
                        </Col>

                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Unlock} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Vehicle Unlocking</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
        </Container>
        </Layout>
    )
};
export default Mechanical;

export const Head = () => (
    <SEO />
  )